import { Card } from "react-bootstrap";
import styled from "styled-components";

const StyledCard = styled(Card)`
  background: rgba(11, 1, 1, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(19px);
  -webkit-backdrop-filter: blur(19px);
  border-color: transparent;
  border-radius: 12px;
  height: 200px;
  justify-content: center;

  @media only screen and (max-width: 500px) {
    height: auto;
    padding-right: 5px;
    justify-content: center;
  }
`;

function BasedCard(props) {
  return <StyledCard className="shadow-sm">{props.children}</StyledCard>;
}

export default BasedCard;
