import styled from "styled-components";
import { Row, Col, Card } from "react-bootstrap";

const WorkExpCard = styled(Card)`
  background: rgba(11, 1, 1, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(19px);
  -webkit-backdrop-filter: blur(44px);
  border-color: transparent;
  border-radius: 12px;
  height: auto;
  justify-content: center;
  padding-bottom: 50px;
`;

const StyledYearText = styled.h5`
  color: #fff;
  font-size: 18px;
`;

const StyledJobText = styled.h5`
  background: -webkit-linear-gradient(#ff003c, #fea794);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
`;

const SectionTitled = styled.h3`
  background: -webkit-linear-gradient(#ff003c, #fea794);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
`;

const StyledLine = styled.hr`
  background: linear-gradient(to right, #1e1e1e, #813234, #1e1e1e);
  height: 1px;
  border: 0;
`;

function WorkExp() {
  return (
    <>
      <Col md={12} className="mt-3 ">
        <WorkExpCard className="shadow-sm">
          <Card.Title className="pt-4 pl-4">
            <SectionTitled>Tech Background</SectionTitled>
            <StyledLine></StyledLine>
          </Card.Title>
          <Row>
            <Col md={3} className="ml-4 pl-3">
              <StyledYearText>2023-Present</StyledYearText>
            </Col>
            <Col md={4} className="ml-4 ml-md-0 pr-4">
              <StyledJobText>Back End Developer</StyledJobText>
              <p className="mb-1 text-white-50">Gunkul Engineering PCL</p>
            </Col>
            <Col md={4} className="ml-4 pr-4 ml-md-0">
              <p className="small text-white-50">
                - Develop and improve in-house energy monitoring platform
                <br></br>- Develop new features on requirements <br></br>-
                Collaborate with PM and BU team to analyze and support live
                incidents from customers
                <br></br>- Troubleshoot and fix defects
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="ml-4 pl-3">
              <StyledYearText>2022-2023</StyledYearText>
            </Col>
            <Col md={4} className="ml-4 ml-md-0 pr-4">
              <StyledJobText>Back End Developer(+DevOps)</StyledJobText>
              <p className="mb-1 text-white-50">True Voice Co.,Ltd</p>
            </Col>
            <Col md={4} className="ml-4 pr-4 ml-md-0">
              <p className="small text-white-50">
                - Improve Voicebot and Chatbot conversation flow on in-house
                platform <br></br>- Develop and maintain unit testing <br></br>-
                Troubleshoot and fix defects<br></br>- Analyze and support live
                incidents from customers
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="ml-4 pl-3">
              <StyledYearText>2019-2021</StyledYearText>
            </Col>
            <Col md={4} className="ml-4 ml-md-0 pr-4">
              <StyledJobText>Full-Stack Developer</StyledJobText>
              <p className="mb-1 text-white-50">
                The federation of thai industries
              </p>
            </Col>
            <Col md={4} className="ml-4 pr-4 ml-md-0">
              <p className="small text-white-50">
                - Collaborating with management, departments and customers to
                identify end-user requirements and specifications <br></br>-
                Producing efficient and elegant code based on requirements{" "}
                <br></br>- Observing user feedback to recommend improvements to
                existing software products
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={3} className="ml-4 pl-3">
              <StyledYearText>2017-2019</StyledYearText>
            </Col>
            <Col md={4} className="ml-4 ml-md-0">
              <StyledJobText>Tech Administrator</StyledJobText>
              <p className="mb-1 text-white-50">GS1 Thailand</p>
            </Col>
            <Col md={4} className="ml-4 ml-md-0 pr-5">
              <p className="small text-white-50">
                - Maintain Computer Systems.<br></br>- Complete System Installs
                and Upgrades.<br></br>- Oversee Data Storage and Retrieval
                Systems.<br></br>- Ensuring security and efficiency of IT
                infrastructure.<br></br>- Designing and delivering the web
                infrastructure.
              </p>
            </Col>
          </Row>
        </WorkExpCard>
      </Col>
    </>
  );
}

export default WorkExp;
