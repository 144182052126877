import styled from "styled-components";
import { Col, Card, Button, Badge } from "react-bootstrap";

const StyledTechCard = styled(Card)`
  background: rgba(11, 1, 1, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(19px);
  -webkit-backdrop-filter: blur(19px);
  border-color: transparent;
  border-radius: 12px;
  height: auto;
  justify-content: center;
  padding-bottom: 50px;
`;

const SectionTitled = styled.h3`
  background: -webkit-linear-gradient(#ff003c, #fea794);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
`;

const ButtonStyled = styled(Button)`
  &:hover {
    background-color: transparent;
    border-color: #aeb4ba;
    color: #aeb4ba;
    border-radius: 10px;
  }
  &:disabled {
    background-color: transparent;
    border-color: #aeb4ba;
    color: #5f6870;
    border-radius: 15px;
    text-align: center;
    opacity: 1;
  }
`;

const StyledLine = styled.hr`
  background: linear-gradient(to right, #1e1e1e, #813234, #1e1e1e);
  height: 1px;
  border: 0;
`;

function TechStack() {
  return (
    <>
      <Col md={12} className="mt-3 mx-auto">
        <StyledTechCard className="shadow-sm">
          <Card.Title className="pt-4 pl-4">
            <SectionTitled>Tech Stack</SectionTitled>
            <StyledLine></StyledLine>
          </Card.Title>
          <Card.Body className="pb-0 pt-1">
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge className="mr-2" variant="light"></Badge>
              Vue{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge variant="light"></Badge>
              React{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge variant="light"></Badge>
              Nuxt.js{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge variant="light"></Badge>
              Next.js{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge variant="light"></Badge>
              Node.js{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              NestJS{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge variant="light"></Badge>
              Express{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              Strapi{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              Wordpress{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              SQL{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              NoSQL{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              Firebase{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              LINE Messaging API{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              Docker{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              NGINX{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              Git{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              Redis{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              GraphQL{" "}
            </ButtonStyled>
            <ButtonStyled disabled className="mx-1 mb-2 text-white-50">
              <Badge></Badge>
              gRPC{" "}
            </ButtonStyled>
          </Card.Body>
        </StyledTechCard>
      </Col>
    </>
  );
}

export default TechStack;
