import { Navbar, Nav } from "react-bootstrap";
import styled from "styled-components";

const StyledNavBar = styled(Navbar)`
  background-color: rgb(245, 245, 245, 0);
`;

const LogoSocial = styled.img`
  height: 25px;
`;

const MainLogo = styled.img`
  height: 45px;
`;

function Header() {
  return (
    <StyledNavBar expand="lg">
      <Navbar.Brand className="font-weight-bold text-dark" href="/">
        <MainLogo src={window.location.origin + "/WT_LOGO.png"} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav>
          <Nav.Link href="https://github.com/tomztomz" target="_blank">
            <LogoSocial src={window.location.origin + "/GH_RED.png"} />
          </Nav.Link>
          <Nav.Link href="https://gitlab.com/tomztomz" target="_blank">
            <LogoSocial src={window.location.origin + "/GL_RED.png"} />
          </Nav.Link>
          <Nav.Link href="https://www.instagram.com/tomz11x/" target="_blank">
            <LogoSocial src={window.location.origin + "/IG_RED.png"} />
          </Nav.Link>
          <Nav.Link href="https://fb.com/tomztnk" target="_blank">
            <LogoSocial src={window.location.origin + "/FB_RED.png"} />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </StyledNavBar>
  );
}

export default Header;
