import { Container } from "react-bootstrap";
import styled from "styled-components";
import Header from "./Header";

const StyledWrapper = styled.div`
  background-image: linear-gradient(
    to top,
    #0a0909,
    #120909,
    #180a0a,
    #1d0a0b,
    #210a0c,
    #210a10,
    #210a13,
    #200a16,
    #1a0b19,
    #140c1a,
    #0d0d19,
    #070d16
  );

  padding-bottom: 30px;
`;

function Wrapper(props) {
  return (
    <>
      <StyledWrapper>
        <Container className="pt-3">
          <Header />
          {props.children}
        </Container>
      </StyledWrapper>
    </>
  );
}

export default Wrapper;
