import { Route, Switch } from 'react-router-dom';

import Page2 from './components/Views/Page2';

import Profile from './components/Views/Profile';
import Wrapper from './components/Layouts/Wrapper';

function App() {
  return (
    <>
      <Wrapper>
        <Switch>
          <Route exact path="/" component={Profile} />
          <Route exact path="/page2" component={Page2} />
        </Switch>
      </Wrapper>
    </>
  );
}

export default App;
