import BasedCard from "../Display/BasedCard";
import styled from "styled-components";
import { Row, Col, Card } from "react-bootstrap";
import WorkExp from "../Display/WorkHisCard";
import { useState } from "react";
import TechStack from "../Display/TechStackCard";

const StyledH3 = styled.h3`
  background: -webkit-linear-gradient(#ff003c, #fea794);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
`;

const StyledH5 = styled.h5`
  color: #859797;
  font-size: 18px;
`;

function Profile() {
  const [isHover, setIsHover] = useState("rounded-circle shadow-lg");

  const hoverEvent = () => {
    setIsHover("rounded-circle shadow-lg animate__animated animate__shakeY");
  };

  const hoverLeaveEvent = () => {
    setIsHover("rounded-circle shadow-lg");
  };

  return (
    <>
      <Row className="mt-2 mb-2 pb-5">
        <Col md={3} className="pr-md-0">
          <BasedCard>
            <div className="text-center mb-2 mt-2">
              <img
                src={window.location.origin + "/31264504.png"}
                alt="Profile"
                className={isHover}
                width="150"
                onMouseEnter={hoverEvent}
                onMouseLeave={hoverLeaveEvent}
              />
            </div>
          </BasedCard>
        </Col>
        <Col md={5} className="pr-md-0 mt-2 mt-md-0 ">
          <BasedCard>
            <div className="ml-4 text-left">
              <Card.Title className="mt-3">
                <h5 className="text-white">HELLO EVERYONE => I AM</h5>
              </Card.Title>
              <Card.Subtitle className="mb-2">
                <StyledH3>Withaya Thingakruea</StyledH3>
              </Card.Subtitle>
              <hr className="mr-4 bg-white"></hr>
              <Card.Text className="pb-2">
                <p className=" small text-light">
                  BACK END DEVELOPER <br></br>at GUNKUL ENGINEERING PCL
                  <br></br>BKK, THAILAND
                </p>
              </Card.Text>
            </div>
          </BasedCard>
        </Col>
        <Col md={4} className="mt-3 mt-md-0">
          <BasedCard>
            <div className="ml-4 mr-2 pt-2 pb-2">
              <StyledH5 className="mt-3 text-white">Summary [ TL;DR ]</StyledH5>
              <hr className="mr-4 mb-2 mt-1"></hr>
              <p className="text-white-50 small pr-4">
                Geek Web Developer with 4+ years of experience in web design,
                adaptation and maintenance. Looking to use extensive knowledge
                and background in frond-end and back-end web development to
                advance and optimize the presence of organization.
              </p>
            </div>
          </BasedCard>
        </Col>
        <WorkExp />
        <TechStack />
      </Row>
    </>
  );
}

export default Profile;
